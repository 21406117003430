/*!
 * Copyright 2022 - Swiss Data Science Center (SDSC)
 * A partnership between École Polytechnique Fédérale de Lausanne (EPFL) and
 * Eidgenössische Technische Hochschule Zürich (ETHZ).
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useGetNamespacesQuery } from "../../features/projects/projects.api";

/**
 *  useGetNamespaces custom hook
 *
 *  UseGetNamespaces.ts
 *  hook to fetch Namespaces
 */
function useGetNamespaces(ownedOnly: boolean) {
  const { data, isFetching, isLoading, refetch } =
    useGetNamespacesQuery(ownedOnly);

  const sortedList = data
    ? [...data].sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
    : [];

  return {
    list: sortedList ?? [],
    fetching: isFetching,
    fetched: !isFetching && !isLoading,
    refetchNamespaces: refetch,
  };
}

export default useGetNamespaces;
