/*!
 * Copyright 2023 - Swiss Data Science Center (SDSC)
 * A partnership between École Polytechnique Fédérale de Lausanne (EPFL) and
 * Eidgenössische Technische Hochschule Zürich (ETHZ).
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import cx from "classnames";
import styles from "./EntityCardSkeleton.module.scss";

interface EntityCardSkeletonProps {
  includeBorder?: boolean;
}
export default function EntityCardSkeleton({
  includeBorder = true,
}: EntityCardSkeletonProps) {
  return (
    <div
      className={cx(
        styles.skeletonContainer,
        includeBorder ? styles.skeletonContainerBorder : ""
      )}
    >
      <div className={cx(styles.skeletonCardImg, styles.skeletonLoading)}></div>
      <div className={cx(styles.skeletonCardBtnContainer)}>
        <div
          className={cx(styles.skeletonCardBtn, styles.skeletonLoading)}
        ></div>
      </div>
      <div className={styles.skeletonCardLines}>
        <div className={styles.skeletonLoading}></div>
        <div className={styles.skeletonLoading}></div>
        <div className={styles.skeletonLoading}></div>
        <div className={styles.skeletonLoading}></div>
        <div className={styles.skeletonLoading}></div>
        <div className={styles.skeletonLoading}></div>
      </div>
    </div>
  );
}
